<template>
  <div id="app">
    <div class="maxWidth">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    // document.addEventListener(
    //   "keydown",
    //   function (event) {
    //     if (
    //       (event.ctrlKey === true || event.metaKey === true) &&
    //       (event.keyCode === 61 ||
    //         event.keyCode === 107 ||
    //         event.keyCode === 173 ||
    //         event.keyCode === 109 ||
    //         event.keyCode === 187 ||
    //         event.keyCode === 189)
    //     ) {
    //       event.preventDefault();
    //     }
    //   },
    //   false
    // );
    document.addEventListener(
      "mousewheel",
      function (e) {
        e = e || window.event;
        // @ts-ignore
        if ((e.wheelDelta && event.ctrlKey) || e.detail) {
          event.preventDefault();
        }
      },
      {
        capture: false,
        passive: false,
      }
    );
    // 添加 resize 事件监听器
    // window.addEventListener("resize", this.checkResize);
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听器
    // window.removeEventListener("resize", this.checkResize);
  },
  methods: {
    // checkResize() {
    //   if (window.innerWidth !== this.lastWidth) {
    //     this.lastWidth = window.innerWidth; // 更新 lastWidth
    //     location.reload(); // 刷新页面
    //   }
    // },
  },
};
</script>

<style lang="scss">
*::-webkit-scrollbar {
  position: absolute;
  left: 0;
  width: 3px;
  height: 0;
  display: none;
}

*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #ddd;
}
html,
body {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  scroll-behavior: smooth;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}
input,
textarea {
  -webkit-user-select: auto !important;
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  -o-user-select: auto !important;
  user-select: auto !important;
}

.icon,
.iconfont {
  font-family: "iconfont" !important;
  font-size: 20px !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.maxWidth {
  max-width: 600px;
  height: 100vh;
  margin: 0 auto;
  background-color: #f6f7f9;
  overflow: auto;
}
.van-cell__title {
  text-align: left;
}
.navBar {
  position: fixed;
  top: 0;
  width: 600px;
  z-index: 2;
}
/deep/.el-dialog__wrapper {
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .el-dialog {
    margin: 30px auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: normal;
  }
}
@media screen and (max-width: 1024px) {
  .navBar {
    width: 100%;
  }
}
</style>
