//登录 Login
export const Login = {
  title: '客户支持中心',
  dec: '请登录以获得最佳支持',
  password: '密码',
  confirm: '登录',
  other: '或使用',
  accountPlaceholder: '输入电子邮件或电话号码',
  passwordPlaceholder: '输入密码',
  nofb: 'fb未加载',
  accountPlaceholder: '请输入账号',
  passwordPlaceholder: '请输入密码',
  forgetPassword: '忘记密码？',
  login: '登录',
  register: '注册',
  other: '其他登录方式',
  emailPlaceholder: '请输入邮箱',
  codePlaceholder: '请输入验证码',
  getCode: '获取验证码',
  accountLogin: '账号登录',
  codeLogin: '验证码登录',
  emailCheck: '您还未填写邮箱！',
  accountCheck: '请输入正确的账号或密码',
  codeCheck: '请输入正确的验证码',
  newPassword: '输入新密码',
  repeatPassword: '重复新密码',
  registerSuccess: '注册成功',
  differencePassword: '两次输入的密码不一致',
  noData: '请将信息填充完整',
  changePassword: '修改密码',
  PasswordSuccess: '修改成功',
  install: '安装程序',
  bind: '账号绑定',
  otherBind: '其他绑定方式',
}

//顶部标签 Home
export const Home = {
  exit: '退出登录',
  sysName: '工单系统',
  confirmExit: '确认退出',
  remain: '提醒',
  home: '我的工单',
  userOrder: '提交工单',
  table: '工单列表',
}

//顶部标签 Home
export const userOrder = {
  chooseGame: '选择游戏',
  chooseGameplaceholder: '请选择游戏',
  chooseServer: '选择服务器',
  chooseServerplaceholder: '请选择服务器',
  chooseRole: '选择角色',
  chooseRoleplaceholder: '请选择角色',
  problemStyle: '问题类型',
  problemStyleplaceholder: '请选择问题类型',
  decPlaceholder: '请选择问题描述',
  contactPlaceholder: '请输入您的电子邮件或电话号码',
  unusualLogin: '登录异常',
  unusualAccount: '账户异常',
  unusualNap: '充值异常',
  unusualOther: '其他',
  decProblem: '描述问题',
  uploadImg: '上传图片',
  confirmUpload: '确认上传',
  confirmUploadDec: '只能上传jpg/png格式的文件，不能超过20MB',
  uploadVideo: '上传视频文件',
  confirmUploadVideoDec: '只能上传mp4文件（视频文件）',
  contact: '请输入您的电子邮件地址或Facebook链接',
  confirm: '提交信息',
  maxChooseImg: '当前最多只能选择3个文件',
  onlyJpg: '只能上传jpg/png格式的图像！',
  maxSize: '文件上传不能超过20MB！',
  uploadVideoMsg: '请上传视频文件！',
  uploadSuccess: '上传成功',
  uploadFail: '上传失败,',
  onlyOneFile: '当前最多只能选择一个文件',
}

//表格
export const table = {
  decProblem: '问题描述',
  status: '状态',
  statusPlaceholder: '请选择工单状态',
  problemStyle: '问题类型',
  problemStyleplaceholder: '请选择问题类型',
  unusualLogin: '登录异常',
  unusualAccount: '账户异常',
  unusualNap: '充值异常',
  unusualOther: '其他',
  search: '搜索',
  Reset: '重置',
  noData: '暂无数据',
  role: '角色',
  contact: '联系方式',
  star: '星级评定',
  img: '图片',
  video: '视频',
  noUseVideo: '您的浏览器不支持此视频格式。',
  operate: '操作',
  reOpenTicket: '重新开启工单',
  closeTicket: '关闭工单',
  replyTicket: '工单回复',
  evaluate: '评定',
  feedback: '查看回复',
  Processing: '正在处理',
  Processed: '已处理',
  closed: '关闭',
  confirmOpenTicket: '确定重新开启工单?',
  remail: '提醒',
  confirmCloseTicket: '确认关闭工单?',
}

//查看客服回复
export const replyDialog = {
  title: '客服回复',
  noUseVideo: '您的浏览器不支持此视频格式。',
  user: '用户',
  send: '发送',
  confirm: '确认',
}

//评价
export const starDialog = {
  title: '评价',
  satisfaction: '满意度',
  other: '其他意见',
  Reset: '重置',
  satisfaction1: '非常差',
  satisfaction2: '差',
  satisfaction3: '正常',
  satisfaction4: '满意',
  satisfaction5: '非常满意',
  gradePlaceholder: '请选择星级评价',
  commentPlaceholder: '请输入评价',
}

//工单回复
export const userReplyDialog = {
  title: '工单回复',
  decProblem: '描述问题',
  uploadImg: '上传图片',
  confirmUpload: '确认上传',
  confirmUploadDec: '只能上传jpg/png格式的文件，不能超过20MB',
  uploadVideo: '上传视频文件',
  confirmUploadVideoDec: '只能上传mp4文件（视频文件）',
  confirm: '确认',
  decPlaceholder: '请选择问题描述',
  maxChooseImg: '当前最多只能选择3个文件',
  onlyJpg: '只能上传jpg/png格式的图像！',
  maxSize: '文件上传不能超过20MB！',
  uploadVideoMsg: '请上传视频文件！',
  uploadSuccess: '上传成功',
  uploadFail: '上传失败,',
  onlyOneFile: '当前最多只能选择一个文件',
}