import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import { Loading } from 'element-ui';
import axios from "./utils/axios";
import lodash from 'lodash'
import VueI18n from 'vue-i18n'
import GSignInButton from 'vue-google-signin-button'
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/fonts.css"
import "./assets/fonts/iconfont/iconfont.css"
//引入vant
import Vant from 'vant';
import 'vant/lib/index.css';
import locale from 'element-ui/lib/locale'
import localeZh from 'element-ui/lib/locale/lang/zh-CN';
import localeEn from 'element-ui/lib/locale/lang/en';
import localeId from 'element-ui/lib/locale/lang/id';
import localeVi from 'element-ui/lib/locale/lang/vi';
import localeTh from 'element-ui/lib/locale/lang/th';

Vue.config.productionTip = false;
Vue.prototype._ = lodash
Vue.prototype.$http = axios;
Vue.prototype.address = 'http://192.168.110.22'
Vue.prototype.$pathName = 'http://192.168.110.22'
Vue.prototype.$isMobile = /Mobile|Android|iphone|ipad|ipod|IOS|Macintosh|Surface Pro 7/i.test(navigator.userAgent);
Vue.use(Loading.directive);
Vue.use(GSignInButton);
Vue.use(VueI18n);
Vue.use(Vant);



const languageList = ["VI", "ID", "EN", "TH", "ZH"];
const lang = languageList.includes(localStorage.getItem('locale')) ? localStorage.getItem('locale') : 'EN'
const i18n = new VueI18n({
  locale: lang,
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'ZH': { ...require('./lang/zh'), ...localeZh }, // 中文语言包
    'EN': { ...require('./lang/en'), ...localeEn }, // 英文语言包
    'ID': { ...require('./lang/id'), ...localeId }, // 印尼语言包
    'TH': { ...require('./lang/th'), ...localeTh }, // 泰国语言包
    'VI': { ...require('./lang/vn'), ...localeVi }, // 越南语言包
  }
})
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});



new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
